<template>
  <div>
    <a-modal
      v-model="visible"
      width="750px"
      :confirmLoading="loading"
      :maskClosable="false"
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? "编辑产品信息" : "新增产品信息" }}</div>
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4, md: 8 }"
          :wrapper-col="{ span: 20, md: 16 }"
        >
          <a-row gutter="12">
            <a-divider orientation="left" id="basic-information">
              基本信息
            </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="number" label="产品编号">
                <a-input v-model="form.number" :disabled="true"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="name" label="产品名称">
                <a-input v-model="form.name" :disabled="form.id ? true:false"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="category" label="分类">
                <a-select
                  v-model.number="form.category"
                  style="width: 100%"
                  :allowClear="true"
                >
                  <a-select-option
                    v-for="item of classificationItems"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
                <!-- <a-button
                  type="primary"
                  style="width: 20%; margin-left: 5%"
                  @click="
                    resetForm();
                    categoryVisible = true;">
                  <a-icon type="plus" />
                </a-button> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="unit" label="单位">
                <a-select v-model.number="form.unit" :allowClear="true">
                  <a-select-option
                    v-for="item of unitItems"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="pic" label="pic">
                <a-input :min="1" v-model.number="form.pic" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="spec" label="规格">
                <a-input v-model.number="form.spec" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="cs_id" label="CS_ID">
                <a-input v-model="form.cs_id" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="capacity" label="容量(mAh)">
                <a-input v-model.number="form.capacity" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="goods_type" label="类型">
                <a-input v-model="form.goods_type" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="voltage" label="电压(V)">
                <a-input v-model="form.voltage" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="rate" label="功率(Wh)">
                <a-input v-model="form.rate" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="color" label="颜色">
                <a-input v-model="form.color" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="net_weight" label="净重(g)">
                <a-input v-model="form.net_weight" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="gross_weight" label="毛重(g)">
                <a-input v-model="form.gross_weight" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="l" label="长(mm)">
                <a-input v-model="form.l" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="w" label="宽(mm)">
                <a-input v-model="form.w" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="h" label="高(mm)">
                <a-input v-model="form.h" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="is_active" label="状态">
                <a-select v-model="form.is_active" style="width: 100%">
                  <a-select-option :value="true">激活</a-select-option>
                  <a-select-option :value="false">冻结</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row gutter="12">
            <a-divider orientation="left" id="price-management">
              价格管理
            </a-divider>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="retail_price" label="零售价(元)">
                <a-input-number
                  v-model="form.retail_price"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>

            <div v-for="item in price_list" :key="item.id">
              <a-col :span="24" :md="12">
                <a-form-model-item
                  :prop="item.CS_ID"
                  :label="item.CS_ID + '(' + item.Location + ')'"
                >
                  <a-input
                    :value="item.Price + '美元'"
                    style="width: 100%"
                    readonly
                  />
                </a-form-model-item>
              </a-col>
            </div>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="level_price1" label="等级价一(元)">
                <a-input-number
                  v-model="form.level_price1"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="level_price2" label="等级价二(元)">
                <a-input-number
                  v-model="form.level_price2"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="level_price3" label="等级价三(元)">
                <a-input-number
                  v-model="form.level_price3"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row gutter="12">
            <a-divider orientation="left" id="graphic-information">
              图文信息
            </a-divider>
            <a-col :span="24" :md="24">
              <a-upload
                action="/api/goods_images/"
                list-type="picture-card"
                :headers="{
                  'X-CSRFToken': Cookies.get('csrftoken'),
                  Authorization: `Bearer ${Cookies.get('access')}`,
                }"
                :file-list="form.image_items"
                @preview="handlePreview"
                @change="handleChange"
                :before-upload="beforeUpload"
                name="file"
                type="drag"
                multiple
                :drag="true"
              >
                <div v-if="form.image_items.length < 8" class="custom-dragger">
                  <a-icon type="inbox" style="font-size: 24px" />
                  <div class="ant-upload-text">
                    <p>点击选择或拖拽多张图片到此区域</p>
                    <p>支持一次性上传最多8张图片</p>
                  </div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-col>
            <a-col :span="24" :md="24">
              <a-textarea
                placeholder="产品详细介绍"
                :rows="4"
                v-model="form.description"
              />
            </a-col>
          </a-row>
          <a-row gutter="12">
            <a-divider orientation="left" id="beginning-inventory">
              期初库存
            </a-divider>
            <a-col :span="24" :md="24">
              <a-table
                rowKey="id"
                :columns="columns"
                :data-source="warehouseItems"
                :loading="loading"
                :pagination="false"
                style="width: 100%"
              >
                <div slot="initial_quantity" slot-scope="value, item">
                  <a-input-number
                    :value="item.initial_quantity"
                    min="1"
                    @change="(value) => changeQuantity(value, item)"
                  />
                </div>
              </a-table>
            </a-col>
          </a-row>
          <a-row gutter="12">
            <a-divider orientation="left" id="inventory-warning">
              库存预警
            </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item
                prop="enable_inventory_warning"
                label="启用库存警告"
              >
                <a-switch
                  checked-children="启用"
                  un-checked-children="禁用"
                  v-model="form.enable_inventory_warning"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_upper" label="库存上限">
                <a-input-number
                  v-model="form.inventory_upper"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_lower" label="库存下限">
                <a-input-number
                  v-model="form.inventory_lower"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <!-- 批次 -->
      <a-modal
        :title="batchTitle"
        v-model="batchVisible"
        width="750px"
        cancelText="关闭"
        :maskClosable="false"
        @cancel="batchVisible = false"
        @ok="confirmChoosed"
      >
        <div style="margin-bottom: 16px">
          <a-button
            type="primary"
            icon="plus"
            style="margin: 0 8px"
            @click="addLine"
            >添加</a-button
          >
        </div>
        <a-table
          rowKey="id"
          :columns="columnsBatch"
          :data-source="batchItems"
          :pagination="false"
          style="width: 100%"
        >
          <div slot="initial_quantity" slot-scope="value, item">
            <a-input-number
              :value="item.initial_quantity"
              min="1"
              @change="
                (value) => changeQuantityBatch(value, item, 'initial_quantity')
              "
            />
          </div>
          <div slot="number" slot-scope="value, item">
            <a-input
              :value="item.number"
              @change="(e) => changeQuantityBatch(e, item, 'number')"
            />
          </div>
          <div slot="production_date" slot-scope="value, item">
            <a-date-picker
              :value="item.production_date"
              valueFormat="YYYY-MM-DD"
              @change="
                (value) => changeQuantityBatch(value, item, 'production_date')
              "
            />
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button icon="minus" @click="removeLine(item)"></a-button>
          </div>
        </a-table>
      </a-modal>
    </a-modal>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {
  goodsInformationCreate,
  goodsInformationUpdate,
  goodsCsPrice,
} from "@/api/goods";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  name: "FormModal",
  props: [
    "visible",
    "form",
    "warehouseItems",
    "classificationItems",
    "unitItems",
  ],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      Cookies,
      batchTitle: "管理批次",
      batchVisible: false,
      loading: false,
      price_list: [],
      columns: [
        {
          title: "仓库",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "初始库存",
          dataIndex: "initial_quantity",
          key: "initial_quantity",
          scopedSlots: { customRender: "initial_quantity" },
        },
      ],
      isKeepAdd: false,
      categoryForm: {},
      categoryVisible: false,
      rules: {
        name: [
          { required: true, message: "请输入产品名称", trigger: "change" },
        ],
        number: [
          { required: true, message: "请输入产品编号", trigger: "change" },
        ],
        pic: [
          { required: true, message: "pic为必填项", trigger: "blur" },
          {
            type: "number",
            min: 1,
            message: "必须大于或等于1",
            trigger: "blur",
          },
        ],
        capacity: [
          { required: true, message: "容量必填", trigger: "blur" },
          { type: "number", min: 1, message: "必须大于0", trigger: "blur" },
        ],
      },
      previewVisible: false,
      previewImage: "",
      columnsBatch: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "初始库存",
          dataIndex: "initial_quantity",
          key: "initial_quantity",
          scopedSlots: { customRender: "initial_quantity" },
        },
        {
          title: "生产日期",
          dataIndex: "production_date",
          key: "production_date",
          scopedSlots: { customRender: "production_date" },
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: "80px",
        },
      ],
      curWarehouse: {},
      batchItems: [],
    };
  },

  methods: {
    getCsPrice(good_name) {
      console.log("good_name", good_name);
      goodsCsPrice({ good_name: good_name }).then((data) => {
        // this.cs_sale_hk
        console.log(data);
        this.price_list = data["cs_price"];
      });
    },
    chooseBatch(item) {
      this.batchTitle = "管理批次-" + item.name;
      this.curWarehouse = item;
      if (item.batch_items) {
        this.batchItems = item.batch_items;
      } else {
        this.batchItems = [
          {
            key: "uni1",
            number: "",
            initial_quantity: 1,
            production_date: null,
          },
        ];
      }
      this.batchVisible = true;
    },
    confirmChoosed() {
      let ifHasEmpty = false;
      let sumAmount = 0;
      this.batchItems.map((item) => {
        sumAmount += item.initial_quantity;
        if (!item.number || !item.initial_quantity || !item.production_date) {
          ifHasEmpty = true;
        }
      });
      if (ifHasEmpty) {
        this.$message.warn("请将批次信息填写完整!");
        return;
      }
      let tmp = {
        ...this.curWarehouse,
        ...{ batch_items: this.batchItems, initial_quantity: sumAmount },
      };
      this.warehouseItems = this.$functions.replaceItem(
        this.warehouseItems,
        tmp
      );
      this.batchVisible = false;
    },
    addLine() {
      const { batchItems } = this;
      const newData = {
        key: "uni" + batchItems.length + 1,
        number: "",
        initial_quantity: 1,
        production_date: null,
      };
      this.batchItems = [...batchItems, newData];
    },
    removeLine(item) {
      this.batchItems = this.$functions.removeItemBatch(this.batchItems, item);
    },
    changeQuantity(value, item) {
      item["initial_quantity"] = value || 0;
      this.warehouseItems = this.$functions.replaceItem(
        this.warehouseItems,
        item
      );
    },
    changeQuantityBatch(e, item, pro) {
      if (pro === "number") {
        item[pro] = e.target.value;
      } else {
        item[pro] = e;
      }
      this.batchItems[item.key - 1] = item;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.$set(this.form, "image_items", fileList);
    },
    beforeUpload() {},
    handleCancel() {
      this.previewVisible = false;
    },

    beforeSubmit(formatData) {
      const numericFields = [
        "h",
        "l",
        "voltage",
        "rate",
        "net_weight",
        "gross_weight",
      ];
      numericFields.forEach((field) => {
        if (!formatData[field] || isNaN(formatData[field])) {
          formatData[field] = 0; // 默认值为 0
        }
      });
      return formatData;
    },

    confirm() {
      let image_items = this.form.image_items.map((item) => {
        return item.response.id;
      });
      let inventory_items = this.warehouseItems.map((item) => {
        return {
          warehouse: item.id,
          initial_quantity: item.initial_quantity,
          batch_items: this.form.enable_batch_control ? item.batch_items : [],
        };
      });
      let formatData = {
        ...this.form,
        ...{
          goods_images: image_items,
          inventory_items,
        },
      };
      formatData = this.beforeSubmit(formatData);
      console.log(formatData);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id
            ? goodsInformationUpdate
            : goodsInformationCreate;
          func(formatData)
            .then((data) => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .catch((err) => {
              // 判断错误是否有响应对象
              if (err.response) {
                // 处理包含错误的响应数据
                const errorMessage = JSON.stringify(err.response.data, null, 2); // `null, 2` 用于美化格式，2表示缩进为2个空格
                this.$message.error(errorMessage);
              } else {
                // 处理其他类型的错误（例如网络错误）
                this.$message.error("请求错误");
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped>
/* 自定义拖拽区域样式 */
.custom-dragger {
  padding: 16px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px dashed #d9d9d9;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.custom-dragger:hover {
  border-color: #1890ff;
}

.ant-upload-text {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
}

.ant-upload-text p {
  margin: 4px 0;
}
</style>